<template>
    <div>

        <div class="d-flex justify-content-center h-100 content">
            <b-row class="w-100" style="max-width: 1200px;">
                <b-col md="12">
                    <h1 class="mt-3" style="font-size: 25px; font-weight: 600;">Enterprise Services</h1>
                    <h2 style="font-size: 20px;" class="mt-3">Fully funded services built to complement and enhance your
                        digital strategy.</h2>
                </b-col>


                <b-col md="8" class="mt-4-lg">

                    <div class="mt-4">

                        <div class="d-flex w-100" style="gap: 30px; margin-top: 50px;">
                            <b-img src="@/assets/es_icon_1.png" style="align-self: start; max-width: 90px;"/>
                            <div>
                                <h3>ADDP Digital Dealer Reporting (DDR)</h3>
                                <p>Simplifies reporting to view and track performance across website, digital advertising,
                                digital retailing, call volume and more.</p>
                            </div>
                        </div>

                        <div class="d-flex w-100 mt-3" style="gap: 30px; margin-top: 50px;">
                            <b-img src="@/assets/es_icon_2.png" style="align-self: start; max-width: 90px;"/>
                            <div>
                                <h3>Field Support & Dealer Consulting</h3>
                                <p>Provides in-dealership support that creates a customized digital strategy to achieve
                                dealership success and maximized performance.</p>
                            </div>
                        </div>

                        <div class="d-flex w-100 mt-3" style="gap: 30px; margin-top: 50px;">
                            <b-img src="@/assets/es_icon_3.png" style="align-self: start; max-width: 75px;"/>
                            <div style="padding-left: 15px;">
                                <h3>Base Reputation Management</h3>
                                <p>Supports multiple reputation and review platforms to help quickly identify improvement
                                opportunities in order to enhance your brand visibility and online reputation.</p>
                            </div>
                        </div>

                        <div class="d-flex w-100 mt-3" style="gap: 30px; margin-top: 50px;">
                            <b-img src="@/assets/es_icon_4.png" style="align-self: start; max-width: 75px;"/>
                            <div style="padding-left: 15px;">
                                <h3>Business Listing Syndication</h3>
                                <p>Syndicates accurate dealership contact and operational information to highly visible social
                                sites, such as Google Business, Bing, Facebook and Apple Maps.</p>
                            </div>
                        </div>

                        <div class="d-flex w-100 mt-3" style="gap: 30px; margin-top: 50px;">
                            <b-img src="@/assets/es_icon_5.png" style="align-self: start; max-width: 75px;"/>
                            <div style="padding-left: 15px;">
                                <h3>Call Tracking & Training</h3>
                                <p>Tracks and transcribes all inbound calls across all eligible ADDP portfolio services to
                                understand effectiveness of digital efforts and improve call tracking processes.</p>
                            </div>
                        </div>


                    </div>

                </b-col>

                <b-col md="4">

                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

export default {
    name: 'faq',
    components: {

    },

    metaInfo: {
        title: 'Enterprise Services'
    },

    data() {
        return {

        }
    },

    methods: {

    },

    computed: {

    },
}



</script>

<style>
h3 {
    font-size: 18px;
    font-weight: 600;
}

.no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
  supported by Chrome and Opera */
}

.content {
    margin-top: 20px;
}

@media screen and (max-width: 768px) {

    .content {
        margin-top: 80px;
    }

}
</style>